import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import SearchPage from './components/SearchPage.vue';
//import SignInPage from './components/SignInPage.vue';

// Define the routes
const routes = [
  {
    path: '/',
    name: 'HomePageRoot',
    component: HomePage,
  },
  {
    path: '/home',
    name: 'HomePageAlias',
    component: HomePage,
  },
  {
    path: '/search/:id/:name',
    name: 'SearchPage',
    component: SearchPage,
  },
];

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
